//////////////////////////
// Initialize AdminPlus //
//////////////////////////

// Sidebars
AdminPlus.Sidebar.init()

// Scrollable
AdminPlus.Scrollable()

/////////////////////////////////////
// Custom initializers can go here //
/////////////////////////////////////
